<template>
  <v-container class="lighten-5">
    <v-row no-gutters justify="center" v-if="selectedParishId">
      <v-col class="pa-2" cols="12" md="6">
        <v-select
            v-model="selectedParishId"
            item-text="nom"
            item-value="id"
            :items="parishes"
            filled
            outlined
        >
          <template v-slot:item="{ active, item, attrs, on }">
            <item-select :item="item" :active="active" :attrs="attrs" :on="on"></item-select>
          </template>
          <template v-slot:selection="{  item, index  }">
            <v-row no-gutters align="center" :ref="index">
              <v-img :src="'./parishes/'+item.id+'.png'" max-width="50"></v-img>

              <span>{{ item.nom }}</span>
            </v-row>
          </template>
        </v-select>
      </v-col>
    </v-row>


    <general-statistics :generalStats="generalStats" v-if="generalStats"></general-statistics>

    <baptism-statistics :selected-parish-id="selectedParishId" v-if="generalStats && generalStats.nbrTaufen > 0"/>


    <v-row no-gutters v-if="generalStats && generalStats.nbrEhen > 0">
      <v-col class="pa-2" cols="12" md="12">
        <h1>Mariage</h1>
      </v-col>
    </v-row>
    <by-year-statistics v-if="generalStats && generalStats.nbrEhen > 0" title="mariages"
                        :fetchFunction="_getNbrEhenByParish"
                        :parishId="selectedParishId"></by-year-statistics>

    <v-row no-gutters v-if="generalStats && generalStats.nbrToten > 0">
      <v-col class="pa-2" cols="12" md="12">
        <h1>Décès</h1>
      </v-col>
    </v-row>
    <by-year-statistics v-if="generalStats && generalStats.nbrToten > 0" title="décès"
                        :fetchFunction="_getNbrTotenByParish"
                        :parishId="selectedParishId"></by-year-statistics>


  </v-container>
</template>

<script>


import {
  getGeneralStats,
  getNbrEhenByParish,
  getNbrTotenByParish,
  getParishes
} from "@/api/requests";
import Preloader from "@/components/Preloader";
import ItemSelect from "@/components/ItemSelect";
import GeneralStatistics from "@/components/statistics/GeneralStatistics";
import BaptismStatistics from "@/components/statistics/BaptismStatistics";

export default {
  name: "Statistics",
  components: {
    BaptismStatistics,
    GeneralStatistics,
    ItemSelect,
  },
  data: () => ({
    generalStats: null,
    selectedParishId: null,
    parishes: null
  }),
  async mounted() {
    this.parishes = await getParishes();
    this.selectedParishId = 1;
    this.generalStats = await getGeneralStats(this.selectedParishId);
  },
  methods: {
    _getNbrEhenByParish(parishId) {
      return getNbrEhenByParish(parishId);
    },
    _getNbrTotenByParish(parishId) {
      return getNbrTotenByParish(parishId);
    },
  },
  watch: {
    async selectedParishId(v) {
      this.selectedParishId = v;
      this.generalStats = await getGeneralStats(this.selectedParishId);
    }
  },
}
</script>

<style scoped>

</style>