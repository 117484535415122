var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.rows)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('h1',[_vm._v(_vm._s(_vm.parish.nom + " - " + _vm.translate[_vm.registerType]))])]):_vm._e(),(this.rows)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":this.headers,"items":this.rows,"custom-filter":this.customFilter,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Rechercher"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.genre",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"black"},attrs:{"color":_vm.getColor(item.genre),"dark":""}},[_vm._v(" "+_vm._s(item.genre)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date ? _vm.formatDateLocal(item.date) : item.date)+" ")]}},{key:"item.nom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nom ? item.nom.toUpperCase() : item.nom)+" ")]}},{key:"item.nomP",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomP ? item.nomP.toUpperCase() : item.nomP)+" ")]}},{key:"item.nomM",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomM ? item.nomM.toUpperCase() : item.nomM)+" ")]}},{key:"item.nomEpoux",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEpoux ? item.nomEpoux.toUpperCase() : item.nomEpoux)+" ")]}},{key:"item.nomEpouse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEpouse ? item.nomEpouse.toUpperCase() : item.nomEpouse)+" ")]}},{key:"item.nomEpouxM",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEpouxM ? item.nomEpouxM.toUpperCase() : item.nomEpouxM)+" ")]}},{key:"item.nomEpouseM",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEpouseM ? item.nomEpouseM.toUpperCase() : item.nomEpouseM)+" ")]}}],null,false,1902307510)}):_c('preloader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }