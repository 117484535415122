import Parishes from "@/components/Parishes";
import Registers from "@/components/Registers";
import VueRouter from "vue-router";
import Vue from "vue";
import Table from "@/components/Table";
import Statistics from "@/components/Statistics";

Vue.use(VueRouter);
const routes = [
    {path: '/', component: Parishes,},
    {path: "/register/:parishId", name: "register", component: Registers, props: true},
    {path: "/data/:parishId/:registerType", name: "data", component: Table, props: true},
    {path: "/stats", name: "stats", component: Statistics, props: true},
]

export const router = new VueRouter({routes});