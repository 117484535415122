<template>
  <preloader v-if="loading"/>
  <div v-else>
    <h2>{{ title }}</h2>
    <apexchart type="pie" :options="template.options"  :series="template.series"></apexchart>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";

export default {
  name: "PieChart",
  components: {Preloader},
  props: ["fetchFunction", "parishId", "title", "colors", "replacementLabels"],
  data: () => (
      {
        loading: true,
        data: null,
        template: {
          options: null,
          series: []
        }
      }),
  async mounted() {
    this.data = await this.fetchFunction(this.parishId);
    this.updateChart();
    this.loading = false;
  },
  methods: {
    updateChart() {

      let labels = Object.keys(this.data)

      if (this.replacementLabels){
        for (let i = 0; i < labels.length; i++) {
          labels[i] = this.replacementLabels[labels[i]]
        }
      }

      this.template.series = Object.values(this.data);

      this.template.options = {
        chart: {
          id: 'vuechart-example',
        },
        colors:this.colors,
        labels: labels,
      }
    }
  },
  watch: {
    async parishId(v) {
      this.loading = true;
      this.data = await this.fetchFunction(this.parishId);
      this.updateChart();
      this.loading = false;
    }
  },
}
</script>

<style scoped>

</style>