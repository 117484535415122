<template>
  <v-card
      class="mx-auto pa-3"
      max-width="344"
      outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          Registres de paroisse
        </div>
        <v-list-item-title class="text-h5 mb-1">
          {{ parish.nom }}
        </v-list-item-title>
        <v-list-item-subtitle class="subtitle" v-html="this.description_text"></v-list-item-subtitle>
      </v-list-item-content>

      <!--      <v-list-item-avatar-->
      <!--          tile-->
      <!--          size="50"-->
      <!--          color="grey"-->
      <!--      ></v-list-item-avatar>-->
      <v-img :src="imgSrc" max-width="50"></v-img>
    </v-list-item>

    <v-card-actions>
      <v-btn
          @click="parishClick(parish.id)"
          outlined
          rounded
          text
      >
        Consulter
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CardParoisse",
  props: ["parish", "imgSrc"],
  data: () => ({
    description_text: null
  }),
  mounted() {
    if (this.parish.taufen[0].total !== 0)
      this.description_text = `${this.parish.taufen[0].total} <b>baptêmes</b> entre ${this.parish.taufen[0].minYear} et ${this.parish.taufen[0].maxYear}\n`
    if (this.parish.ehen[0].total !== 0)
      this.description_text += `${this.parish.ehen[0].total} <b>mariages</b> entre ${this.parish.ehen[0].minYear} et ${this.parish.ehen[0].maxYear}\n`
    if (this.parish.toten[0].total !== 0)
      this.description_text += `${this.parish.toten[0].total} <b>décès</b> entre ${this.parish.toten[0].minYear} et ${this.parish.toten[0].maxYear}\n`
  },
  methods: {
    parishClick(parishId) {
      this.$router.push({
        name: "register",
        params: {
          parishId: parishId,
        },
      }).catch();
    },
  }
}
</script>

<style scoped>
.subtitle {
  -webkit-line-clamp: 3 !important;
  white-space: pre-line !important;
}
</style>