<template>
  <v-card class="mx-auto pa-3" max-width="344" outlined v-if="this.register">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          {{ this.nameParish }}
        </div>
        <v-list-item-title class="text-h5 mb-1 capitalize-first">
          {{ this.nameRegister }}
        </v-list-item-title>
        <v-list-item-subtitle class="subtitle">
          {{ this.register.total }}<b> {{ this.nameRegister }} </b> entre {{ this.register.minYear }} et
          {{ this.register.maxYear }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-icon x-large>
        {{ this.icon }}
      </v-icon>
    </v-list-item>
    <v-card-actions>
      <v-btn @click="registerClick(parishId, registerType)" outlined rounded text>
        Consulter
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CardRegister",
  props: ["register", "nameRegister", "nameParish", "icon", "registerType", "parishId"],
  methods: {
    registerClick(parishId, registerType) {
      this.$router.push({
        name: "data",
        params: {
          parishId: parishId,
          registerType: registerType
        },
      }).catch();
    },
  }
}
</script>

<style scoped>
.capitalize-first {
  text-transform: capitalize;
}
</style>