import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Contains the default content of the state (managed by VueX library).
// This hold all the data of the app.
export default new Vuex.Store({
    state: {
        dataTable: {

            headers: {
                headers_taufen: [
                    //{text: 'ID', value: 'id'},
                    {text: 'N°', value: 'no'},
                    {text: 'Page', value: 'page'},
                    {text: 'Date', value: 'date', divider: true},
                    {text: 'Nom', value: 'nom'},
                    {text: 'Prénom', value: 'prenom'},
                    {text: 'Sexe', value: 'genre'},
                    {text: 'Nom du père', value: 'nomP'},
                    {text: 'Prénom du père', value: 'prenomP'},
                    {text: 'Nom de la mère', value: 'nomM'},
                    {text: 'Prénom de la mère', value: 'prenomM'},
                    {text: 'Domicile', value: 'domicile'},
                    {text: 'Commentaire', value: 'commentaire'},
                    //{text: 'RefM', value: 'refM'},
                    //{text: 'RefD', value: 'refD'},
                    //{text: 'Externe', value: 'externe'},
                    //{text: 'Oops', value: 'oops'}
                ],
                headers_ehen: [
                    {text: "ID", value: "id"},
                    {text: "N°", value: "no"},
                    {text: "Page", value: "page"},
                    {text: "Date", value: "date", divider: true},

                    {text: "Nom de l'époux", value: "nomEpoux"},
                    {text: "Prénom de l'époux", value: "prenomEpoux"},
                    {text: "Prénom du père de l'époux", value: "prenomEpouxP"},
                    {text: "Nom de la mère de l'époux", value: "nomEpouxM"},
                    {text: "Prénom de la mère de l'époux", value: "prenomEpouxM"},
                    {text: "Domicile de l'époux", value: "domicileEpoux", divider: true},

                    {text: "Nom de l'épouse", value: "nomEpouse"},
                    {text: "Prénom de l'épouse", value: "prenomEpouse"},
                    {text: "Prénom du père de l'épouse", value: "prenomEpouseP"},
                    {text: "Nom de la mère de l'épouse", value: "nomEpouseM"},
                    {text: "Prénom de la mère de l'épouse", value: "prenomEpouseM"},
                    {text: "Domicile de l'épouse", value: "domicileEpouse"},

                    {text: "Commentaire", value: "commentaire"}
                ],
                header_toten: [
                    {text: "ID", value: "id"},
                    {text: "N°", value: "no"},
                    {text: "Page", value: "page"},
                    {text: "Date", value: "date", divider: true},

                    {text: "Nom", value: "nom"},
                    {text: "Prénom", value: "prenom"},
                    {text: "Sexe", value: "genre"},
                    {text: "Nom du père", value: "nomP"},
                    {text: "Prénom du père", value: "prenomP"},
                    {text: "Nom de la mère", value: "nomM"},
                    {text: "Prénom de la mère", value: "prenomM"},


                    {text: "Commentaire", value: "commentaire"},
                ]
            }
        }
    },
    actions: {
        formatDate(dateStr) {
            return dateStr
        }
    }
});
