<template>
  <div>
    <v-row no-gutters v-if="this.rows" justify="center"><h1>{{ parish.nom + " - " + translate[registerType] }}</h1>
    </v-row>

    <v-data-table v-if="this.rows"
                  :headers="this.headers"
                  :items="this.rows"
                  class="elevation-1"
                  :custom-filter="this.customFilter"
                  :search="search"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Rechercher"
            class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.genre="{ item }">
        <v-chip style="color:black"
                :color="getColor(item.genre)"
                dark
        >
          {{ item.genre }}
        </v-chip>
      </template>


      <template v-slot:item.date="{ item }">
        {{
          item.date ? formatDateLocal(item.date) : item.date
        }}
      </template>

      <!-- taufen et toten-->
      <template v-slot:item.nom="{ item }">
        {{ item.nom ? item.nom.toUpperCase() : item.nom }}
      </template>
      <template v-slot:item.nomP="{ item }">
        {{ item.nomP ? item.nomP.toUpperCase() : item.nomP }}
      </template>
      <template v-slot:item.nomM="{ item }">
        {{ item.nomM ? item.nomM.toUpperCase() : item.nomM }}
      </template>

      <!-- ehen -->
      <template v-slot:item.nomEpoux="{ item }">
        {{ item.nomEpoux ? item.nomEpoux.toUpperCase() : item.nomEpoux }}
      </template>
      <template v-slot:item.nomEpouse="{ item }">
        {{ item.nomEpouse ? item.nomEpouse.toUpperCase() : item.nomEpouse }}
      </template>
      <template v-slot:item.nomEpouxM="{ item }">
        {{ item.nomEpouxM ? item.nomEpouxM.toUpperCase() : item.nomEpouxM }}
      </template>
      <template v-slot:item.nomEpouseM="{ item }">
        {{ item.nomEpouseM ? item.nomEpouseM.toUpperCase() : item.nomEpouseM }}
      </template>
    </v-data-table>
    <preloader v-else/>
  </div>
</template>

<script>
import {getEhenByParish, getParish, getTaufenByParish, getTotenByParish} from "@/api/requests";
import Preloader from "@/components/Preloader";
import {formatDate} from "@/utils";

export default {
  name: "Table",
  props: ["parishId", "registerType"],
  components: {Preloader},
  data: () => ({
    rows: null,
    search: "",
    page: 1,
    numberByPage: 50,
    headers: null,
    parish: null,
    translate: {"taufen": "Baptêmes", "ehen": "Mariages", "toten": "Décès"}
  }),

  async mounted() {
    this.parish = await getParish(this.parishId);

    switch (this.registerType) {
      case "taufen":
        this.rows = await getTaufenByParish(this.parishId, null, null);
        this.headers = this.$store.state.dataTable.headers.headers_taufen
        break;
      case "ehen":
        this.rows = await getEhenByParish(this.parishId, null, null);
        this.headers = this.$store.state.dataTable.headers.headers_ehen
        break;
      case "toten":
        this.rows = await getTotenByParish(this.parishId, null, null);
        this.headers = this.$store.state.dataTable.headers.header_toten
    }
  },
  methods: {
    getColor(genre) {
      if (genre === "f") return '#f8bbd0'
      else if (genre === "m") return '#bbdefb'
      else return 'white'
    },
    formatDateLocal(dateString) {
      return formatDate(dateString)
    },
    customFilter(value, search, item) {
      const s = search.split(" ");

      // AND logic
      let res = true;
      for (let i = 0; i < s.length; i++) {
        let res_ = false;
        for (const key in item) {
          res_ |= ("" + item[key]).toLowerCase().includes(("" + s[i]).toLowerCase())
        }
        res &= res_;
      }
      return res;

      // OR logic
      // let res = false;
      // for (let i = 0; i < s.length; i++) {
      //   res |= ("" + value).toLowerCase().includes(("" + s[i]).toLowerCase())
      // }
      // return res
    },
  },
}
</script>

<style scoped>

</style>