import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true
    },
    theme: {
        themes: {
            light: {
                primary: "#009688",
                secondary: "#ff9800",
                accent: "#00bcd4",
                error: "#f44336",
                warning: "#ffc107",
                info: "#2196f3",
                success: "#4caf50"
            }
        }
    }
});
