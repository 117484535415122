const month_fr = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]

export function formatDate(dateString) {
    const parts = dateString.split("-");
    if (parts.length !== 3)
        return dateString;

    let day = parts[2];
    if (!day.includes("?")) {

        if (day[0] === "0")
            day = day[1];

        if (day === "0")
            day = ""
    }

    let month = parts[1];
    if (!month.includes("?")) {
        if (month === "00")
            month = "?"
        else if (month[0] === "0")
            month = month_fr[parseInt(month[1]) - 1];
        else
            month = month_fr[parseInt(month) - 1];
    }


    let year = parts[0]
    if (!year.includes("?")) {
        if (year === "0000")
            year = ""
    }

    let environ = false
    if (dateString[dateString.length - 1] === "~")
        environ = true

    if (environ)
        return "env. " + day + " " + month + " " + year

    return day + " " + month + " " + year
}