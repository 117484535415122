var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"lighten-5"},[(_vm.selectedParishId)?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"item-text":"nom","item-value":"id","items":_vm.parishes,"filled":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('item-select',{attrs:{"item":item,"active":active,"attrs":attrs,"on":on}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-row',{ref:index,attrs:{"no-gutters":"","align":"center"}},[_c('v-img',{attrs:{"src":'./parishes/'+item.id+'.png',"max-width":"50"}}),_c('span',[_vm._v(_vm._s(item.nom))])],1)]}}],null,false,3472774166),model:{value:(_vm.selectedParishId),callback:function ($$v) {_vm.selectedParishId=$$v},expression:"selectedParishId"}})],1)],1):_vm._e(),(_vm.generalStats)?_c('general-statistics',{attrs:{"generalStats":_vm.generalStats}}):_vm._e(),(_vm.generalStats && _vm.generalStats.nbrTaufen > 0)?_c('baptism-statistics',{attrs:{"selected-parish-id":_vm.selectedParishId}}):_vm._e(),(_vm.generalStats && _vm.generalStats.nbrEhen > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","md":"12"}},[_c('h1',[_vm._v("Mariage")])])],1):_vm._e(),(_vm.generalStats && _vm.generalStats.nbrEhen > 0)?_c('by-year-statistics',{attrs:{"title":"mariages","fetchFunction":_vm._getNbrEhenByParish,"parishId":_vm.selectedParishId}}):_vm._e(),(_vm.generalStats && _vm.generalStats.nbrToten > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","md":"12"}},[_c('h1',[_vm._v("Décès")])])],1):_vm._e(),(_vm.generalStats && _vm.generalStats.nbrToten > 0)?_c('by-year-statistics',{attrs:{"title":"décès","fetchFunction":_vm._getNbrTotenByParish,"parishId":_vm.selectedParishId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }