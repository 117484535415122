<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-2" cols="12" md="12">
        <h1>Globales</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pa-2" cols="12" md="4">
        <v-card
            class="mx-auto pa-3"
            max-width="344"
            outlined
        >
          <div v-if="this.generalStats">
            <p class="subtitle-1">Nombre de baptêmes</p>
            <h3>{{ this.generalStats.nbrTaufen }}</h3>
          </div>
          <preloader v-else></preloader>
        </v-card>
      </v-col>
      <v-col class="pa-2" cols="12" md="4">
        <v-card
            class="mx-auto pa-3"
            max-width="344"
            outlined
        >
          <div v-if="this.generalStats">
            <p class="subtitle-1">Nombre de mariages</p>
            <h3>{{ this.generalStats.nbrEhen }}</h3>
          </div>
          <preloader v-else></preloader>
        </v-card>
      </v-col>
      <v-col class="pa-2" cols="12" md="4">
        <v-card
            class="mx-auto pa-3"
            max-width="344"
            outlined
        >
          <div v-if="this.generalStats">
            <p class="subtitle-1">Nombre de décès</p>
            <h3>{{ this.generalStats.nbrToten }}</h3>
          </div>
          <preloader v-else></preloader>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";

export default {
  name: "GeneralStatistics",
  props: ["generalStats"],
  components: {Preloader}
}
</script>

<style scoped>

</style>