import {request} from "./request";

// ========== Samples ==========


// Return the count of samples using filters
export async function getParishes() {
    return await request("parish", {
        method: "GET"
    });
}

export async function getParish(parish_id) {
    return await request(`parish/${parish_id}`, {
        method: "GET"
    });
}

export async function getRegisters() {
    return await request("register", {
        method: "GET"
    });
}

export async function getParishRegisters(parish_id) {
    return await request(`register/${parish_id}`, {
        method: "GET"
    });
}

export async function getTaufenByParish(parish_id, page_nbr, nbr) {
    return await request(`taufen`, {
        method: "POST",
        body: {
            "parishId": parish_id,
            "pageNbr": page_nbr,
            "pageNo": nbr
        }
    });
}

export async function getEhenByParish(parish_id, page_nbr, nbr) {
    return await request(`ehen`, {
        method: "POST",
        body: {
            "parishId": parish_id,
            "pageNbr": page_nbr,
            "pageNo": nbr
        }
    });
}

export async function getTotenByParish(parish_id, page_nbr, nbr) {
    return await request(`toten`, {
        method: "POST",
        body: {
            "parishId": parish_id,
            "pageNbr": page_nbr,
            "pageNo": nbr
        }
    });
}

export async function getGeneralStats(parish_id) {
    return await request(`stats/${parish_id}`, {
        method: "GET"
    })
}

export async function getNbrTaufenByParish(parish_id) {
    return await request(`stats/taufen/by_year/${parish_id}`, {
        method: "GET"
    });
}

export async function getNbrEhenByParish(parish_id) {
    return await request(`stats/ehen/by_year/${parish_id}`, {
        method: "GET"
    });
}

export async function getNbrTotenByParish(parish_id) {
    return await request(`stats/toten/by_year/${parish_id}`, {
        method: "GET"
    });
}

export async function getNbrNameByParish(parish_id, sex) {
    return await request(`stats/taufen/nametop10/${sex}/${parish_id}`, {
        method: "GET"
    });
}

export async function getNbrFnameByParish(parish_id) {
    return await request(`stats/taufen/fnametop10/${parish_id}`, {
        method: "GET"
    });
}

export async function getNbrSexByParish(parish_id) {
    return await request(`stats/taufen/sex/${parish_id}`, {
        method: "GET"
    });
}