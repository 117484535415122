<template>
  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
    <v-row no-gutters align="center" :disabled="active">
      <v-img :src="'./parishes/'+item.id+'.png'" max-width="50"></v-img>
      <span>{{ item.nom }}</span>
    </v-row>
  </v-list-item>
</template>

<script>
export default {
  name: "ItemSelect",
  props: ["item", "on", "attrs", "active"]
}
</script>

<style scoped>

</style>