<template>
  <div>
    <v-row no-gutters>
      <v-col class="pa-2" cols="12" md="12">
        <h1>Baptêmes</h1>
      </v-col>
    </v-row>


    <v-row no-gutters>
      <v-col class="pa-2" cols="12" md="12">
        <by-year-statistics v-if="selectedParishId" title="Baptêmes par année" label="nombre"
                            :fetchFunction="_getNbrTaufenByParish" :parishId="selectedParishId"></by-year-statistics>
      </v-col>
    </v-row>


    <v-row no-gutters>
      <v-col class="pa-2" cols="12" md="6">
        <nom-bar-statistics v-if="selectedParishId" :fetch-function="_getNbrFnameByParish" :parishId="selectedParishId"
                            label="nombre" title="Noms de famille" color="#616161"/>
      </v-col>
      <v-col class="pa-2" cols="12" md="6">
        <pie-chart v-if="selectedParishId" :fetch-function="_getNbrSexByParish" :parishId="selectedParishId"
                   title="Sexes" :colors="['#f48fb1','#81d4fa','#616161']"
                   :replacement-labels="{'m':'masculin','f':'féminin','u':'inconnu'}"/>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pa-2" cols="12" md="6">
        <prenoms-bar-statistics v-if="selectedParishId" :fetch-function="_getNbrNameByParish" label="nombre"
                                :parishId="selectedParishId" title="Prénoms féminins" :sex="'f'" color="#f48fb1"/>
      </v-col>
      <v-col class="pa-2" cols="12" md="6">
        <prenoms-bar-statistics v-if="selectedParishId" :fetch-function="_getNbrNameByParish" label="nombre"
                                :parishId="selectedParishId" title="Prénoms masculins" :sex="'m'" color="#81d4fa"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PieChart from "@/components/statistics/PieChart";
import NomBarStatistics from "@/components/statistics/NomBarStatistics";
import PrenomsBarStatistics from "@/components/statistics/PrenomsBarStatistics";
import ByYearStatistics from "@/components/statistics/ByYearStatistics";

import {
  getNbrFnameByParish,
  getNbrNameByParish,
  getNbrSexByParish,
  getNbrTaufenByParish,
} from "@/api/requests";

export default {
  name: "BaptismStatistics",
  components: {
    PieChart,
    NomBarStatistics,
    PrenomsBarStatistics,
    ByYearStatistics
  },
  props: ["selectedParishId"],
  methods: {
    _getNbrTaufenByParish(parishId) {
      return getNbrTaufenByParish(parishId);
    },
    _getNbrNameByParish(parishId, sex) {
      return getNbrNameByParish(parishId, sex);
    },
    _getNbrFnameByParish(parishId) {
      return getNbrFnameByParish(parishId);
    },
    _getNbrSexByParish(parishId) {
      return getNbrSexByParish(parishId);
    }
  },
}
</script>

<style scoped>

</style>