<template>
  <preloader v-if="loading"/>
  <div v-else>
    <h2>{{ title }}</h2>
    <apexchart type="bar" :options="template.options" :series="template.series"></apexchart>
  </div>
</template>

<script>
import Preloader from "@/components/Preloader";

export default {
  name: "PrenomsBarStatistics",
  components: {Preloader},
  props: ["fetchFunction", "parishId", "title", "sex", "color", "label"],
  data: () => (
      {
        loading: true,
        data: null,
        template: {
          options: null,
          series: []
        }
      }),
  async mounted() {
    this.data = await this.fetchFunction(this.parishId, this.sex);
    this.updateChart();
    this.loading = false;
  },
  methods: {
    updateChart() {
      this.template.series[0] = {
        name: this.label,
        data: Object.values(this.data)
      };

      this.template.options = {
        chart: {
          id: 'vuechart-example',
        },
        colors: [this.color],
        xaxis: {
          categories: Object.keys(this.data),
          tickAmount: 20,
        }
      }
    }
  },
  watch: {
    async parishId(v) {
      this.loading = true;
      this.data = await this.fetchFunction(this.parishId, this.sex);
      this.updateChart();
      this.loading = false;
    }
  },
}
</script>

<style scoped>

</style>