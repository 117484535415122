<template>
  <v-container class="lighten-5">
    <v-row no-gutters v-if="loaded" justify="center"><h1>{{ parish.nom }}</h1></v-row>
    <v-row no-gutters v-if="loaded">
      <v-col class="pa-2" cols="12" sm="4">
        <card-register v-if="taufen" :name-parish="parish.nom" name-register="baptêmes"
                       :register="taufen" icon="mdi-baby-face-outline" :parish-id="this.parishId"
                       register-type="taufen"></card-register>
      </v-col>
      <v-col class="pa-2" cols="12" sm="4">
        <card-register v-if="ehen" :name-parish="parish.nom" name-register="mariages" :register="ehen" icon="mdi-ring"
                       :parish-id="this.parishId" register-type="ehen"></card-register>
      </v-col>
      <v-col class="pa-2" cols="12" sm="4">
        <card-register v-if="toten" :name-parish="parish.nom" name-register="décès" :register="toten"
                       icon="mdi-grave-stone" :parish-id="this.parishId" register-type="toten"></card-register>
      </v-col>
    </v-row>
    <preloader v-else/>
  </v-container>
</template>

<script>
import Preloader from "@/components/Preloader";
//import CardParoisse from "@/components/CardParoisse";

import {getParish, getParishRegisters} from "@/api/requests";
import CardRegister from "@/components/CardRegister";

export default {
  name: "Register",
  components: {CardRegister, Preloader,},
  props: ["parishId"],
  data: () => ({
    parish: null,
    taufen: null,
    ehen: null,
    toten: null,
    loaded: false
  }),
  async mounted() {

    const registers = await getParishRegisters(this.parishId);
    this.taufen = registers["taufen"].total > 0 ? registers["taufen"] : null
    this.ehen = registers["ehen"].total > 0 ? registers["ehen"] : null
    this.toten = registers["toten"].total > 0 ? registers["toten"] : null

    this.parish = await getParish(this.parishId);

    this.loaded = true;
  },
}
</script>

<style scoped>

</style>