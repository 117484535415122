<template>
  <v-container class="lighten-5">
    <v-row no-gutters v-if="parishes" justify="center"><h1>Paroisses</h1></v-row>
    <v-row no-gutters v-if="parishes">
      <v-col class="pa-2"
             v-for="p in parishes"
             :key="p.id"
             cols="12"
             sm="4"
      >
        <card-parish :img-src="'./parishes/'+p.id+'.png'" :parish="p"></card-parish>
      </v-col>
    </v-row>
    <preloader v-else/>
  </v-container>
</template>

<script>
import CardParish from "@/components/CardParish";
import {getRegisters, getParishes} from "@/api/requests";
import Preloader from "@/components/Preloader";

export default {
  name: 'Paroisses',
  components: {Preloader, CardParish},
  data: () => ({
    parishes: null,
    description_formatted: null
  }),
  async mounted() {
    let parishes = await getParishes();
    this.description_formatted = {};
    const register = await getRegisters();

    for (let i = 0; i < parishes.length; i++) {
      parishes[i]["taufen"] = register["taufen"].filter(
          (value) => {
            return value.id === parishes[i].id;
          }
      );
      parishes[i]["ehen"] = register["ehen"].filter(
          (value) => {
            return value.id === parishes[i].id;
          }
      );
      parishes[i]["toten"] = register["toten"].filter(
          (value) => {
            return value.id === parishes[i].id;
          }
      );
    }

    this.parishes = parishes;

  }
}
</script>
