
// Abort controller permit to cancel request to avoid race conditions
// see: https://sebastienlorber.com/handling-api-request-race-conditions-in-react

class Aborter {
    constructor() {
        this.abortControllers = {};
    }

    replace(id) {
        this.stop(id);
        return this.start(id);
    }

    start(id) {
        const abortController = new AbortController();
        this.abortControllers[id] = abortController;

        return abortController;
    }

    stop(id) {
        if (Object.prototype.hasOwnProperty.call(this.abortControllers, id))
            this.abortControllers[id].abort();
    }
}

const aborter = new Aborter();

// Basic request
// If using singalId (to cancel running request), the call must be in try/catch call to be notified of the abort
export async function request(route, parameters = {}) {
    const method = Object.prototype.hasOwnProperty.call(parameters, "method") ? parameters["method"] : "GET";
    const body = Object.prototype.hasOwnProperty.call(parameters, "body") ? parameters["body"] : null;
    const signalId = Object.prototype.hasOwnProperty.call(parameters, "signalId") ? parameters["signalId"] : null;
    const accept = Object.prototype.hasOwnProperty.call(parameters, "accept") ? parameters["accept"] : "application/json";
    const contentType = Object.prototype.hasOwnProperty.call(parameters, "contentType") ? parameters["contentType"] : "application/json";
    const parseResponse = Object.prototype.hasOwnProperty.call(parameters, "parseResponse") ? parameters["parseResponse"] : "json";
    //const url = `${process.env.VUE_APP_APIURL}${route}`;
    //const url = `http://localhost:1909/${route}`;
    const url = `https://rpfr-api.herokuapp.com/${route}`;

    // Abort old request if still active and create a signal for the current one
    const abortControllerSignal = signalId ? aborter.replace(signalId).signal : null;

    const headers = new Headers();
    if (accept)
        headers.append("Accept", accept);
    if (contentType)
        headers.append("Content-Type", contentType);


    try {
        const parameters = {
            method,
            mode: "cors",
            headers,
            signal: abortControllerSignal,
        };
        if (body)
            parameters["body"] = body instanceof FormData || contentType !== "application/json"
                ? body
                : JSON.stringify(body);

        const response = await fetch(url, parameters);


        if (response.ok) {

            if (parseResponse === "json") {
                const jsonResponse = await response.json();
                return jsonResponse;
            } else if (parseResponse === "blob") {
                return {status: "success"};
            } else
                return null;
        }

        throw await buildError(response);

    } catch (e) {

        // Re-throw DOMException indicating aborted request, so that called (component) know that it was aborted
        if (e instanceof DOMException)
            throw e;
        else if (e instanceof HTTPException) {
            throw e;
        }

        return null;
    }
}

export class HTTPException {
    constructor(code, status = 400) {
        this.code = code;
        this.status = status;
    }
}

async function buildError(response) {
    const json = await response.json();
    let errorCode = getErrorCode(json);
    if (errorCode === null)
        errorCode = response.status;
    return new HTTPException(errorCode, response.status);
}

export function getErrorCode(responseBody) {
    if (responseBody.detail && responseBody.detail.indexOf(":") > 0)
        return +(responseBody.detail.split(":")[0]);
    else
        return null;
}
